var showPassBtn = document.querySelectorAll(".showPassBtn_open");
var hidePassBtn = document.querySelectorAll(".showPassBtn_close");
var passInput = document.querySelectorAll(".pass__wrap input");
showPassBtn.forEach(function (item, i) {
    showPassBtn[i].addEventListener("click", function () {
        passInput[i].setAttribute("type", "text");
        hidePassBtn[i].style.display = "block";
        showPassBtn[i].style.display = "none";
    });
    hidePassBtn[i].addEventListener("click", function () {
        passInput[i].setAttribute("type", "password");
        hidePassBtn[i].style.display = "none";
        showPassBtn[i].style.display = "block";
    });
});
